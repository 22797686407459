h1 {
    font-family: 'MyriadPro-Bold', sans-serif!important;
    font-size: 1.125rem;
    line-height: 1.5rem;
}

h2 {
    font-family: 'MyriadPro-Semibold-Condensed', sans-serif!important;
    font-size: 3.75rem;
    line-height: 4.375rem;
    color: #004261;
    // font-weight: 600;
}

h3 {
    font-family: 'MyriadPro-Cond', sans-serif!important;
    font-size: 2.188rem;
    line-height: 2.5rem;
    color: #004261;
    font-weight: 600;
}

h4 {
    font-family: 'MyriadPro-Cond', sans-serif!important;
    font-size: 1rem;
    line-height: 1.313rem;
    color: #004261;
}

h5 {
    font-family: 'MyriadPro-Cond', sans-serif!important;
    font-size: 2.188rem;
    line-height: 2.5rem;
    color: #004261;
}

h6 {
    font-family: 'MyriadPro-Cond', sans-serif!important;
    font-size: 1rem;
    line-height: 2.5rem;
    color: #004261;
}

p {
    font-family: 'MyriadPro-Light', sans-serif;
    font-size: 1.125rem;
    line-height: 1.75rem;
    color: #000;
}

li {
    font-family: 'MyriadPro-Light', sans-serif;
    font-size: 1.125rem;
    line-height: 1.75rem;
    color: #000;
}

a {
    text-decoration: none;
    color: #004261!important;
    font-weight: bold;
}

blockquote {
    background-color: #fff;
    color: #004261!important;
    border-left: 0.7rem solid #004261;
    margin: 2rem;
    padding: 0.5em 0.7rem;
    font-family: 'MyriadPro-Light', sans-serif;
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: bold;
}

pre {
    font-family: 'MyriadPro-Light', sans-serif;
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    font-size: 0.875em;
}

.doe {
    position: fixed;
    right: -20px;
    transform: rotate(-90deg);
    top: 70%;
    z-index: 100;
}

.caixa-alta {
    text-transform: uppercase;
}

// CORES
.azul {
    color: #004261;
}

.branco {
    color: #fff;
}

.preto {
    color: #000;
}

// MENU
#menu {
    background-color: #004261;
    color: #fff;
}

.invert {
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
}

.nav-link {
    font-family: "MyriadPro-Semibold", sans-serif;
    color: #fff!important;
    font-weight: 600!important;
    font-size: 13px;
}

.hidden-menu {
    background-color: #004261;
    z-index: 5000;
}

.app-search-close-button {
    background-color: transparent;
    color: white;
    font-family: 'MyriadPro-Regular';
    border: 0;
    font-size: 1rem;
}

// BUSCA
.text-search {
    width: 130px;
    box-sizing: border-box;
    border: 0;
    border-radius: 4px;
    background-color: transparent;
    background-image: url('/images/lupa_busca.png');
    background-position: 10px 10px;
    background-repeat: no-repeat;
    padding: 7px 10px 7px 35px;
    -webkit-transition: width 0.4s ease-in-out;
    transition: width 0.4s ease-in-out;
    color: #6795b6 !important;
}

.text-search:focus {
    outline: 0;
    border: 1px solid #fff;
    border-radius: 32px;
    font-family: "MyriadPro-Regular", sans-serif;
    font-weight: 600 !important;
    font-size: 0.813rem;
    padding-top: 12px;
}

#busca::-webkit-input-placeholder {
    color: transparent!important;
}

#busca:focus::-webkit-input-placeholder {
    color: #fff!important;
}

.search-group-title {
    padding-left: 1rem!important;
    font-weight: 600!important;
}

.search-group-item {
    padding-left: 1.5rem!important;
}

.search-group-item:hover {
    background-color: #004261!important;
    color: #fff!important;
}

.search-group-item a:hover {
    background-color: transparent!important;
    border: 0px!important;
}

.ui-widget {
    border-radius: 5px!important;
    width: 600px!important;
    position: fixed!important;
    top: 10%!important;
    left: 50%!important;
    transform: translate(-50%, 0)!important;
}

// HOME
.capa-home {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 600px;
    border-bottom: 5px solid #004261;
    color: #fff;
}

.destaque-home {
    background-image: url("/images/home/abre-home.jpg");
}

.live-badge {
    background-color: red;
    border-radius: 12px;
}

.aovivo {
    font-family: 'MyriadPro-BoldCond', sans-serif!important;
    font-size: 17px;
    color: #fff;
}

.aovivo a {
    font-family: "MyriadPro-Cond", sans-serif !important;
    font-size: 17px;
    color: #004261!important;
    font-weight: 500;
}

.tit-youtube {
    font-family: 'MyriadPro-Cond', sans-serif!important;
    color: #004261;
    font-weight: 400;
}

.tit-home {
    font-family: 'MyriadPro-BoldCond', sans-serif!important;
    font-size: 2.5rem;
    line-height: normal;
}

.tit-home-menor {
    font-family: 'MyriadPro-BoldCond', sans-serif!important;
    font-size: 1.75rem;
    line-height: normal;
}

.dest-home {
    // height: 475px;
    background-color: #004261;
    margin-bottom: 60px !important;
}

.noticia-destaque {
    width: 100%;
}

.subtit-home {
    color: #fff;
}

.btn-saiba-mais {
    font-family: 'MyriadPro-Regular', sans-serif!important;
    background-color: #fff;
    border-radius: 32px;
    color: #1c6ba4;
    font-weight: 600;
}

.btn-saiba-mais:hover {
    background-color: #1c6ba4;
    color: #fff;
}

.tit-modulo {
    font-family: 'MyriadPro-Cond', sans-serif!important;
    font-size: 2.188rem;
    color: #004261;
}

.form-label {
    font-family: "MyriadPro-Regular", sans-serif;
    text-decoration: none;
    color: #1c6ba4!important;
    font-size: .688rem;
}

#newsletter-home {
    .form-control {
        border-radius: 10px;
        border: 1px solid #6795b6!important;
    }
}

.btn-news-home {
    font-family: 'MyriadPro-Regular', sans-serif!important;
    background-color: #6796b4!important;
    color: #fff!important;
    border-radius: 32px;
}

.btn-news-home:hover {
    background-color: #004261!important;
}

.insta-thumb {
    width: 253px;
    height: 253px;
    object-fit: cover;
}

// FOOTER
.footer {
    font-family: "MyriadPro-Regular", sans-serif;
    background-color: #004261;
    color: #d9edfc;
    font-size: 1.5rem;
}

.menu-footer {
    font-family: 'MyriadPro-Bold', sans-serif;
    font-size: 0.938rem;
    line-height: 1.063rem;
    color: #fff;
}

.item-footer {
    font-family: 'MyriadPro-Regular', sans-serif;
    font-size: 0.938rem;
    line-height: 1.063rem;
    color: #fff;
    font-style: normal;
    font-weight: 400;
}

.tit-footer {
    font-family: 'MyriadPro-Regular', sans-serif;
    font-size: 1.25rem;
    color: #d9edfc;
}

.footer-black {
    font-family: 'MyriadPro-Bold', sans-serif;
    background-color: #000;
    color: #fff;
    font-size: 0.875rem;
}

.link-tit-footer {
    font-size: 0.813rem!important;
    line-height: 1rem!important;
    color: #fff!important;
    font-weight: normal!important;
}

.tit-footer-black {
    font-size: 0.813rem;
    line-height: 1rem;
    color: #fff;
}

.tit-footer-black a {
    color: #fff!important;
    font-family: "MyriadPro-Light", sans-serif;
    font-size: 0.688rem;
    line-height: 1rem;
}

.item-footer-black {
    font-family: "MyriadPro-Light", sans-serif;
    font-size: 0.688rem;
    line-height: 1rem;
    color: #fff;
}

.item-footer-black a {
    color: #fff!important;
    font-family: "MyriadPro-Light", sans-serif;
    font-size: 0.688rem;
    line-height: 1rem;
}

.hr-footer {
    height: 2px!important;
}

#footer a {
    color: #fff;
    text-decoration: none;
}

#footer {
    text-transform: uppercase;
}

// Search Bar
#search {
    input {
        background-color: transparent;
        color: #fff;
        border: 1px solid;
        border-radius: 32px;
        padding: 0 10px;
    }
    input:focus-visible {
        outline: #fff;
    }
}

.search-item .search-group-title {
    font-size: large;
    font-weight: bold;
    margin-bottom: 1rem;
}

.search-item .search-group-item {
    margin-top: 1rem;
}

// CONTEÚDO
.sub-tit {
    font-family: "MyriadPro-lt", sans-serif;
}

.capa {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 475px;
    border-bottom: 5px solid #004261;
    color: #fff;
}

.interna {
    border-bottom: 0px;
}

.destaque-quem-somos {
    background-image: url("/images/o-instituto/quem-somos/abre.jpg");
}

.destaque-contribua {
    background-image: url("/images/o-instituto/contribua/abre.jpg");
}

.destaque-lula {
    background-image: url("/images/o-instituto/vida-de-lula/abre.jpg");
}

.destaque-legado {
    background-image: url("/images/legado/abre.jpg");
}

.destaque-educacao {
    background-image: url("/images/brasil-da-mudanca/avancos-sociais/educacao/abre.jpg");
}

.destaque-saude {
    background-image: url("/images/brasil-da-mudanca/avancos-sociais/saude/abre.jpg");
}

.destaque-mcmv {
    background-image: url("/images/brasil-da-mudanca/avancos-sociais/minha-casa-minha-vida/mcmv-abre.jpg");
}

.destaque-agricultura-familiar-e-reforma-agraria {
    background-image: url("/images/brasil-da-mudanca/economia/agricultura-familiar-e-reforma-agraria/abre.jpg");
}

.destaque-desenvolvimento-regional {
    background-image: url("/images/brasil-da-mudanca/economia/desenvolvimento-regional/abre.jpg");
}

.destaque-desenvolvimento-regional {
    background-image: url("/images/brasil-da-mudanca/economia/desenvolvimento-regional/desenvolvimento-abre.jpg");
}

.destaque-pac {
    background-image: url("/images/brasil-da-mudanca/economia/pac/abre.jpg");
}

.destaque-microeconomia {
    background-image: url("/images/brasil-da-mudanca/economia/microeconomia/abre.jpg");
}

.destaque-direitos-humanos {
    background-image: url("/images/brasil-da-mudanca/democracia/direitos-humanos/lula1.jpg");
}

.destaque-cultura {
    background-image: url("/images/brasil-da-mudanca/democracia/cultura/bumbameuboi.jpg");
}

.destaque-participacao {
    background-image: url("/images/brasil-da-mudanca/democracia/participacao-e-transparencia/participacao-abre.jpg");
}

.destaque-comunicacao {
    background-image: url("/images/brasil-da-mudanca/democracia/comunicacao/abre.jpg");
}

.destaque-seguranca {
    background-image: url("/images/brasil-da-mudanca/democracia/seguranca/abre.jpg");
}

.destaque-politica-externa {
    background-image: url("/images/brasil-da-mudanca/brasil-no-mundo/politica-externa/merkel-lula-obama-conversam.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 475px;
    border-bottom: 5px solid #004261;
    color: #fff;
}

.destaque-politica-de-defesa {
    background-image: url("/images/brasil-da-mudanca/brasil-no-mundo/politica-de-defesa/abre.jpg");
}

.destaque-corrupcao {
    background-image: url("/images/brasil-da-mudanca/brasil-no-mundo/combate-a-corrupcao/abre.jpg");
}

.destaque-esportes {
    background-image: url("/images/brasil-da-mudanca/brasil-no-mundo/esportes/abre.jpg");
}

.destaque-meio-ambiente {
    background-image: url("/images/brasil-da-mudanca/brasil-no-mundo/meio-ambiente/floresta.jpg");
}

.destaque-acervo {
    background-image: url("/images/legado/acervo/acervo.jpg");
}

.destaque-acervo-multimidia {
    background-image: url("/images/legado/acervo/abre.jpg");
}

.destaque-premios {
    background-image: url("/images/legado/premios/premios.jpg");
}

.destaque-formacao {
    background-image: url("/images/formacao/abre.jpg");
}

.destaque-seminarios {
    background-image: url("/images/formacao/seminarios-abre.jpg");
}

.box-tema {
    background-color: #004261;
}

.hr-destaque {
    background-color: #004261;
    opacity: 1;
    margin-top: 1.3rem;
}

.linha-fina {
    font-family: "MyriadPro-Semibold", sans-serif;
    font-size: 1.25rem;
    line-height: 1.563rem;
    color: #000;
}

.img-full {
    width: 100%;
}

.legenda {
    font-family: "MyriadPro-Regular", sans-serif;
    font-size: 0.938rem;
    line-height: 1.25rem;
}

.fonte {
    font-family: "MyriadPro-Light", sans-serif;
    font-size: 0.938rem;
    line-height: 1.25rem;
}

.legenda-43 {
    margin-top: -13.3rem;
}

.legenda-432 {
    margin-top: -10.3rem;
}

.area-azul {
    background-color: #cfe1ef;
}

.area-branca {
    background-color: #fff;
}

.topico {
    font-family: "MyriadPro-Regular", sans-serif;
    font-size: 0.75rem;
    line-height: 0.938rem;
    border-bottom: 5px solid #6996af;
}

.hr-interno {
    background-color: #6996af;
}

.linha-topo {
    border-bottom: 5px solid #004261;
}

.sanfona {
    color: #004261;
    border-bottom: 1px solid #004261;
}

// ACCORDION
#accordionExample {
    background-color: transparent;
}

.accordion-button {
    font-family: 'MyriadPro-Bold', sans-serif!important;
    font-size: 1.25rem;
    line-height: 1.375rem;
    color: #004261;
    border-bottom: 1px solid #004261;
}

.accordion-button a {
    color: #004261;
}

.accordion-button:not(.collapsed) {
    color: #004261;
    background-color: transparent;
}

.accordion-item {
    border: 0;
    background-color: transparent!important;
}

.accordion-button::after {
    background-image: url("/images/btn-accordion-abrir.png");
    background-size: 49px;
    width: 50px;
    height: 50px;
}

.accordion-button:not(.collapsed)::after {
    background-image: url("/images/btn-accordion-fechar.png");
    transform: none;
}

.pergunta {
    font-family: 'MyriadPro-Bold', sans-serif;
    font-size: 1rem;
    line-height: 1.25rem;
    color: #6996b0;
}

.resposta {
    font-family: 'MyriadPro-Regular', sans-serif;
    font-size: 1rem;
    line-height: 1.25rem;
}

.faq a {
    font-family: 'MyriadPro-Bold', sans-serif;
    font-size: 1rem;
    line-height: 1.25rem;
    color: #6996b0!important;
    text-decoration: none;
}

.accordion-item:last-of-type .accordion-button.collapsed {
    background-color: transparent;
}

// VIDEOS INCORPORADOS DO YOUTUBE
.embed-youtube {
    position: relative;
    padding-bottom: 44%;
    /* padding-bottom: 56.25%; - 16:9 aspect ratio (most common) */
    /* padding-bottom: 62.5%; - 16:10 aspect ratio */
    /* padding-bottom: 75%; - 4:3 aspect ratio */
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}

.embed-youtube-43 {
    padding-bottom: 75%;
}

.embed-youtube iframe,
.embed-youtube object,
.embed-youtube embed {
    border: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 70%;
}

.ajuste-youtube {
    margin-top: -5rem;
}

// LEGADO
.legado {
    font-family: "MyriadPro-lt", sans-serif;
    font-size: 1.375rem;
    line-height: 1.5rem;
    color: #004261;
}

.legado-home-1 {
    background-image: url('/images/brasil-da-mudanca/multimidia.jpg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 115px!important;
}

.legado-home-2 {
    background-image: url('/images/brasil-da-mudanca/fisico.jpg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 115px!important;
}

.legado-home-3 {
    background-image: url('/images/brasil-da-mudanca/premios.jpg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 115px!important;
}

.legado-tit {
    font-family: 'MyriadPro-Bold', sans-serif!important;
    font-size: 1.188rem;
    line-height: 2.5rem;
    color: #fff;
    padding-top: 5rem;
}

// PREMIOS
.barra-menu {
    padding-left: 1rem;
    padding-right: 1rem;
    color: #1c6ba4;
}

.link-interno {
    font-family: "MyriadPro-Regular", sans-serif;
    text-decoration: none;
    color: #1c6ba4!important;
    font-size: .813rem;
}

.premios {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.data {
    text-decoration: none;
    color: #1c6ba4;
    font-size: .688rem;
    line-height: .813rem;
    opacity: 1;
}

.btn-mostrar-mais {
    font-family: "MyriadPro-Regular", sans-serif;
    background-color: #c7d5e0;
    color: #1c6ba4!important;
    font-size: .813rem;
    border-radius: 10px;
}

.form-select {
    font-family: "MyriadPro-Regular", sans-serif;
    color: #1c6ba4;
    font-size: .813rem;
    border-radius: 10px;
    background-image: url('/images/btn-menu-dropdown.png');
}

.form-check-input {
    margin-top: .1em;
}

.form-select-sm {
    padding-left: 0.3rem;
}

.hr-premios {
    padding-top: 2px;
    color: #004261;
    opacity: .5;
}

// CONTRIBUA
.separation {
    background-color: #cfe1ef;
    padding-bottom: .7rem;
    opacity: 1;
    margin-top: 0;
}

.btn-outline-primary {
    border-color: #004261;
    font-size: 1rem;
    color: #004261;
    font-family: 'MyriadPro-Regular';
    margin: 1rem .5rem;
}

.btn-check:checked+.btn-outline-primary {
    color: #fff;
    background-color: #004261;
    border-color: #004261;
}

// ACERVO FÍSICO
.link-claro {
    font-family: 'MyriadPro-Regular', sans-serif!important;
    font-size: .938rem;
    color: #40a6f2!important;
}

.acervo-search {
    border-bottom-left-radius: 10px!important;
    border-top-left-radius: 10px!important;
}

#fisico {
    input[type=text]:focus {
        width: 130px;
    }
}

#fisico-options {
    .justo {
        line-height: 1rem;
    }
    .form-check-input[type=checkbox] {
        border-color: #1c6ba4;
    }
}

.tit-acervo-fisico {
    font-family: "MyriadPro-Semibold", sans-serif;
    font-size: 1rem;
    line-height: 1.25rem;
    color: #004261;
    font-weight: 600;
}

.txt-acervo-fisico {
    font-size: 1rem;
}

// ACERVO MULTIMIDIA
#btnSearch {
    font-family: "MyriadPro-Regular", sans-serif;
    font-size: 0.813rem;
    font-weight: 600;
    color: #fff!important;
    background-color: transparent;
    border: 0;
}

.fa {
    color: #004261;
}

.form-control::placeholder {
    font-family: "MyriadPro-Regular", sans-serif;
    color: #6795b6;
    font-size: .813rem;
}

.form-control {
    border-radius: 10px 10px 0 0;
    border: 1px solid #6795b6!important;
}

.input-group-text {
    font-family: "MyriadPro-Regular", sans-serif;
    font-size: .813rem;
    color: #fff;
    background-color: #6795b6;
    border: 1px solid #6795b6;
    border-radius: 10px;
}

.link-acervo {
    font-family: 'MyriadPro-Bold', sans-serif!important;
    font-size: 1rem;
    color: #40a6f2;
}

.border-multimidia {
    border-left: 2px solid rgba(0, 66, 97, 0.5);
}

.triangulo {
    color: #004261!important;
    font-size: 1rem!important;
}

.multi-title {
    font-family: 'MyriadPro-Bold', sans-serif!important;
    font-size: 1.063rem;
    color: #004261;
}

.multi-text {
    font-family: 'MyriadPro-Regular', sans-serif!important;
    font-size: 1rem;
    color: #000;
}

.btn-mais {
    font-family: "MyriadPro-Regular", sans-serif;
    background-color: #7ca4bc;
    color: #fff!important;
    font-size: .813rem;
    border-radius: 0px;
    padding: 0.1rem 0.75rem 0rem 0.75rem;
}

.hr-multimidia {
    background-color: #7ca4bc;
    margin-bottom: 0;
    margin-top: 1.4rem;
    height: 2px!important;
}

// DEPOIMENTOS
.barra-vertical {
    border-left: 5px solid #004261;
}

.barra-horizontal {
    border-top: 5px solid #004261;
    padding-top: 1rem;
    margin-top: 2rem;
}

.quem-tit {
    font-family: 'MyriadPro-Bold', sans-serif!important;
    font-size: 1.25rem;
    color: #004261;
    text-transform: uppercase;
    padding-left: 3rem;
}

.depoimento-destaque {
    font-family: 'MyriadPro-Bold', sans-serif!important;
    font-size: 1.625rem;
    color: #40a6f2;
    font-style: italic;
}

.depoimento {
    font-family: 'MyriadPro-Regular', sans-serif!important;
    font-size: 1.25rem;
    color: #666;
    font-style: italic;
}

.quem {
    font-family: 'MyriadPro-Bold', sans-serif!important;
    font-size: .938rem;
    color: #40a6f2!important;
}

.depoimento-povo {
    font-family: 'MyriadPro-Regular', sans-serif!important;
    font-size: 1.125rem;
    color: #666;
    font-style: italic;
}

.graficos {
    z-index: 22;
    color: #000;
}

// VIDA DE LULA
.box-azul {
    font-family: "MyriadPro-Regular", sans-serif;
    background-color: #004261;
    color: #fff !important;
    font-size: 1rem;
    border-radius: 0px;
    padding: 0.3rem 0.75rem 0rem 0.75rem;
    font-weight: 600;
}

.box-azul a {
    color: #fff!important;
}

.page-item:not(:first-child) .page-link {
    font-family: "MyriadPro-Regular", sans-serif;
    color: #004261!important;
    font-weight: 600!important;
    font-size: 0.813rem;
    border: 0;
}

.page-link {
    color: #004261!important;
    font-weight: 600!important;
    font-size: 1rem;
    border: 0;
    display: initial;
    border: 0;
}

.page-link:hover {
    z-index: 2;
    background-color: #e9ecef;
    border: 0;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff!important;
    background-color: #004261;
    border-color: #004261;
}

// NOTÍCIAS
.tit-noticia {
    font-family: 'MyriadPro-Cond', sans-serif!important;
    font-size: 3.75rem;
    line-height: 4.375rem;
    color: #004261;
    font-weight: 400;
}

.noticia-wide {
    width: 100%;
    object-fit: cover;
}

.dest-noticia {
    color: #004261;
    font-weight: 600;
}

.citacao {
    color: #40a6f2;
    font-size: 1.25rem;
    line-height: 2rem;
    font-style: italic;
    padding: 1rem 0;
}

.link-noticia {
    color: #40a6f2!important;
}

.btn-tags {
    border: 1px solid #004261;
    border-radius: 15px;
    color: #004261!important;
    margin-right: 1rem;
    margin-bottom: 1rem;
}

.btn-all {
    font-family: "MyriadPro-Regular", sans-serif;
    border: 0;
    border-radius: 15px;
    color: #004261!important;
    background-color: #c7d5e0;
    margin-right: 1rem;
}

.tit-recomendadas {
    font-family: 'MyriadPro-Cond', sans-serif!important;
    font-size: 2.5rem;
    color: #004261;
    font-weight: 400;
}

.recomendadas {
    font-family: 'MyriadPro-Cond', sans-serif!important;
    font-size: 18px;
    line-height: 1.5rem;
    color: #004261;
    font-weight: 400;
    margin-bottom: 0;
}

.thumb {
    width: 100%;
    object-fit: contain;
}

.tit-news-thumb {
    font-family: "MyriadPro-Semibold", sans-serif;
    font-size: 1rem;
    line-height: 1.25rem;
    color: #004261;
    font-weight: 600;
}

.text-news-thumb {
    font-size: 16px;
    line-height: 21px;
}

.btn-news-mais {
    font-family: 'MyriadPro-Regular', sans-serif!important;
    background-color: #6796b4!important;
    color: #fff!important;
    border-radius: 32px;
}

.btn-news-mais:hover {
    background-color: #004261!important;
}

// QUEM SOMOS
.contato {
    font-family: 'MyriadPro-Regular', sans-serif;
    font-size: 1rem;
    color: #004261;
}

.quem-somos-tit {
    font-family: "MyriadPro-Regular", sans-serif;
    color: #004261!important;
    font-size: 2rem;
    border: 0;
}

.cargo {
    font-family: 'MyriadPro-Regular', sans-serif;
    font-size: 1rem;
    color: #3b7096;
    text-align: center;
}

.nome {
    font-family: 'MyriadPro-Regular', sans-serif;
    font-size: 1rem;
    color: #004261;
    font-weight: 600;
    text-align: center;
}

.mandato {
    font-family: 'MyriadPro-Regular', sans-serif;
    font-size: .875rem;
    color: #3b7096;
    text-align: center;
    margin-bottom: 0;
}

.btn-primary {
    font-family: 'MyriadPro-Regular', sans-serif!important;
    font-size: 1rem!important;
    background-color: #004261!important;
    border-radius: 0!important;
    border: 0!important;
}

.btn-diretoria {
    font-family: "MyriadPro-Regular", sans-serif;
    background-color: #fff;
    color: #004261!important;
    font-size: .813rem;
    border-radius: 32px;
    border: 1px solid #004261;
}

.btn-faca-parte {
    border-radius: 32px;
}

.share-on-facebook {
    cursor: pointer;
}

.share-on-twitter {
    cursor: pointer;
}

.pagination {
    display: inline-flex!important;
}

// CURSOS
.courses-item .course-date {
    text-align: left;
    padding-bottom: .5rem;
    padding-left: 1rem;
    font-family: "MyriadPro-Regular", sans-serif;
    text-decoration: none;
    color: #1c6ba4 !important;
    font-size: 0.688rem;
}

.courses-item .caption p {
    font-family: "MyriadPro-Cond", sans-serif !important;
    font-size: 17px;
    color: #004261;
    font-weight: 500;
    text-align: left;
    padding-left: 1rem;
    padding-right: 1rem;
    line-height: 19px;
}

#collectionsContainer img {
    max-width: 100%;
}