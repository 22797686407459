// Fonts
@import 'fonts.scss';
// Variables
@import 'variables';
// Bootstrap
@import '~bootstrap/scss/bootstrap';
// Font-Awesome
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";
// CSS
@import 'main.scss';
@import 'responsive.scss';
@import '~jquery-ui/themes/base/all.css';
@import 'bootnavbar.scss';