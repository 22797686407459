@charset "utf-8";

@font-face {
  font-family: 'MyriadPro-Semibold';
  src: url('/fonts/MyriadPro-Semibold.eot'); /* IE9 Compat Modes */
  src: url('/fonts/MyriadPro-Semibold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/MyriadPro-Semibold.ttf')  format('truetype'); /* Safari, Android, iOS */
  src: local('/fonts/MyriadPro-Semibold.eot'), url('/fonts/MyriadPro-Semibold.eot');
}

@font-face {
  font-family: 'MyriadPro-Semibold-SemiCondensed';
  src: local('/fonts/MyriadPro-Semibold-SemiCondensed.otf'), url('/fonts/MyriadPro-Semibold-SemiCondensed.otf');
}

@font-face {
  font-family: 'MyriadPro-Semibold-Condensed';
  src: local('/fonts/MyriadPro-Semibold-Condensed.otf'), url('/fonts/MyriadPro-Semibold-Condensed.otf');
}

@font-face {
  font-family: 'MyriadPro-Light';
  src: local('/fonts/MyriadPro-Light.otf'), url('/fonts/MyriadPro-Light.otf');
}

@font-face {
  font-family: 'MyriadPro-Bold';
  src: url('/fonts/MyriadPro-Bold.eot'); /* IE9 Compat Modes */
  src: url('/fonts/MyriadPro-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/MyriadPro-Bold.ttf')  format('truetype'); /* Safari, Android, iOS */
  src: local('/fonts/MyriadPro-Bold.otf'), url('/fonts/MyriadPro-Bold.otf');
}


@font-face {
  font-family: 'MyriadPro-BoldCond';
  src: url('/fonts/MyriadPro-BoldCond.eot'); /* IE9 Compat Modes */
  src: url('/fonts/MyriadPro-BoldCond.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/MyriadPro-BoldCond.ttf')  format('truetype'); /* Safari, Android, iOS */
  src: local('/fonts/MyriadPro-BoldCond.otf'), url('/fonts/MyriadPro-BoldCond.otf');
}


@font-face {
  font-family: 'MyriadPro-Regular';
  src: url('/fonts/MyriadPro-Regular.eot'); /* IE9 Compat Modes */
  src: url('/fonts/MyriadPro-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/MyriadPro-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('/fonts/MyriadPro-Regular.svg#b6b933d6eeb92d33db4fad36c45b0bff') format('svg'); /* Legacy iOS */
       
  font-style:   normal;
  font-weight:  400;
}

@font-face {
  font-family: 'MyriadPro-Cond';
  src: url('/fonts/MyriadPro-Cond.eot'); /* IE9 Compat Modes */
  src: url('/fonts/MyriadPro-Cond.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/MyriadPro-Cond.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('/fonts/MyriadPro-Cond.svg#9dd3dbc010a5d0ffa723ba7bdffb1f79') format('svg'); /* Legacy iOS */
       
  font-style:   normal;
  font-weight:  400;
}