.desativado{
    opacity: .3;
    display: none;
}

.li-menu{
    display: inline-flex;
}

.dropdown-toggle::after {
        display: none;
    }

.dropdown-menu {
    // top: 4rem;
    left: 0;
    margin-top: 0;
    width: 100vw;
    position: fixed!important;
}

.dropdown-menu .dropdown-toggle::after {
    vertical-align: middle;
    border-left: 4px solid;
    border-bottom: 4px solid transparent;
    border-top: 4px solid transparent;
}

.dropdown-menu .dropdown .dropdown-menu {
        // left: 100%;
    top: 6.5rem;
    margin: 0 20px;
    border-width: 0;
}
.menu-2{
    top: 9rem!important;
}

.dropdown-menu .dropdown .dropdown-menu.left {
    right: 100%;
    left: auto;
}

@media (min-width: 768px) {
    .dropdown-menu .dropdown .dropdown-menu {
        margin: 0;
        border-width: 0;
    }
    .dropdown-menu>li a:hover,
    .dropdown-menu>li.show {
        border-bottom: 2px solid #1c6ba4;
        background-color: transparent;
    }
    .dropdown-menu>li.show>a {
        color: white;
    }
}

@media only screen and (max-width: 420px) {
    .li-menu{
        display: inline-flex;
    }
    .dropdown-menu .dropdown .dropdown-menu {
    top: 9rem;
    margin: 0;
}
}