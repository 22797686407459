// MEDIA QUERIES
@media only screen and (max-width: 420px) {
    .col-md-6 {
        width: 100% !important;
        padding-right: 1rem;
        padding-left: 1rem;
    }
    h2{
        font-size: 2rem;
        line-height: 2.2rem;
    }
    h3{
        font-size: 1.75rem;
        line-height: 2rem;
    }
    .ui-widget  {
        border-radius: 5px!important;
        width: 90%!important;
        position: fixed!important;
        top: 10%!important;
        left: 50%!important;
        transform: translate(-50%, 0)!important;
    }
    .aovivo a {
        display: block;
    }
    .note-video-clip{
        width: 100%!important;
    }
    .capa-home {
        height: 150px;
    }
    .tit-home {
        font-size: 1.8rem;
        line-height: 2rem;
    }
    .tit-home-normal {
        font-size: 1.3rem;
        line-height: 1.5rem;
    }
    .legado-tit {
        font-size: 1rem;
        line-height: 1.5rem;
        padding-top: 2rem;
    }
    .capa{
        height: 185px;
    }
    .destaque-politica-externa {
        height: 185px;
    }
    .linha-accordion{
        padding-left: .8rem;
    }
    .social {
        display: inline;
        padding-right: 3rem!important;
    }
    .social li {
        display: inline;
    }
    .linha-fina{
        font-size: 1rem;
        line-height: 1.2rem;
    }
    .embed-youtube iframe,
    .embed-youtube object,
    .embed-youtube embed {
        height: 100%;
    }
    .embed-youtube-p iframe,
    .embed-youtube-p object,
    .embed-youtube-p embed {
        height: 100%;
    }
    .legenda-43{    
        margin-top: -.5rem!important;
    }
    .legenda-432{    
        margin-top: -.5rem;
    }
    .ajuste-youtube{
        margin-top: 3rem;
    }
    .tit-recomendadas{
        font-size: 2rem;
    }
    .barra-menu {
        padding-left: 0.1rem;
        padding-right: 0;
        color: #fff;
    }
    .dropdown-menu[data-bs-popper] {
        top: auto;
        margin-top: 0rem!important;
    }
    .tit-noticia {
        font-size: 2rem;
        line-height: 2.5rem;
    }
  }
@media only screen and (min-width: 1400px) {
    .insta-thumb{
        width: 295px;
        height: 295px;
    }
}
@media only screen and (min-width: 1600px) {
    .destaque-politica-externa{
        height: 80vh;
    }
    .legenda-43{    
        margin-top: -19.3rem;
    }
    .legenda-432{    
        margin-top: -14.5rem;
    }
    .destaque-politica-externa{
        height: 650px;
    }
    .ajuste-youtube{
        margin-top: -10rem;
    }
    .recomendadas {
        font-size: 1.3rem;
    }
}
@media only screen and (min-width: 2000px) {
    .capa-home {
        height: 606px;
        background-color: #004261;
        background-size: 1300px;
    }
}